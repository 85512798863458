import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as usersApi from "../api/Users";
import Swal from 'sweetalert2';

const AuthContext = createContext({});

export function AuthProvider({ children }) {
    const [user, setUser] = useState();

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(true);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (error) setError(null);
    }, [location.pathname]);

    useEffect(() => {
        usersApi.getCurrentUser()
            .then((response) => setUser(response.data))
            .catch((error) => { })
            .finally(() => setLoadingInitial(false));
    }, []);

    function login(usuario) {
        setLoading(true);
        usersApi.csrf()
            .then(response => {
                usersApi.login(usuario.username, usuario.password)
                    .then((response) => {
                        setUser(response);
                        if (response.id_rol === 1) {
                            history.push("/parametricas");
                        }
                        if (response.id_rol === 2) {
                            history.push("/estados_establecimientos");
                        }
                        if (response.id_rol === 3) {
                            history.push("/vacunacion");
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: 'Credenciales Incorrectas',
                                text: error.response.data.message,
                                showConfirmButton: false,
                                timer: 3000
                            });
                            setError(error.response.data.message);
                        }
                    })
                    .finally(() => setLoading(false));
            });

    }

    function logout() {
        Swal.fire({
            title: 'Cerrando sesion...',
            allowOutsideClick: false,
            showConfirmButton: false,
            customClass: {
                container: 'swal2-custom-container'
            },
            didOpen: () => {
                Swal.showLoading();
            },
        });
        usersApi.logout().then(() => setUser(undefined)).finally(() => Swal.close());
    }

    const memoedValue = useMemo(
        () => ({
            user,
            loading,
            error,
            login,
            logout,
        }),
        [user, loading, error]
    );

    return (
        <AuthContext.Provider value={memoedValue}>
            {!loadingInitial && children}
        </AuthContext.Provider>
    );
}

export default function useAuth() {
    return useContext(AuthContext);
}

