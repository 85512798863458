import api from '../config/Axios';

export async function login ( username, password ) {
    let usuario = {
        username: username,
        password: password
    }
    const response = await api.post('api/login', usuario)
    return response.data.user;
}

export async function csrf () {
    const response = await api.get('/sanctum/csrf-cookie');
    return response;
}

export async function getCurrentUser(){
    const response = api.get('api/usuario')
    return response;
}

export async function logout() {
    const response = api.post('api/logout');
    return response;
}