import { AppRouter } from "./router/AppRouter";
import React from 'react';

function App() {
  return (
    <div className="App">
        <AppRouter/>
    </div>
  );
}

export default App;
