import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL: baseUrl,
    headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
    withCredentials: true
});

export default api;